import React from "react";

// templates
import { LayoutContainer } from '../layout/Layout';

const Oops = ({ className }) => {
  return (
    <LayoutContainer className={className} >
      <h1>Oops</h1>
    </LayoutContainer>
  )
}

export default Oops;