import React, { useState, useEffect } from "react";
import { useRoutes, Link } from "react-router-dom";

// routes
import { RoutesDefault, RoutesAlbums } from './routes/routes-public';

// layout
import { LayoutMain, LayoutHeader } from './layout/Layout';
import { Nav } from './components/Nav';
import Button from "./components/TagButton";

export default function App() { 
  const [isNav, setIsNav] = useState(false);
  const [description, setDescription] = useState([]);
  const [externalLinks, setExternalLinks] = useState([]);
  
  useEffect(() => {
    const { REACT_APP_DATA } = process.env;
    const jsonData = `${REACT_APP_DATA}/digital_properties.json`;

    fetch(jsonData)
      .then(response => response.json())
      .then(data => {
        setDescription(data[0].description),
          filterLinks(data[1].links)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const filterLinks = (data) => {
    // remove current url specify url to remove (first value)
    data.splice(0, 1)
    setExternalLinks(data)
  }

  // listen for nav to lock body and toggle class
  useEffect(() => {
    let body = document.body;

    if (isNav) {
      body.classList.add("locked");
    }
    if (!isNav) {
      body.classList.remove("locked");
    }

  }, [isNav]);

  const toggleNav = () => {
    setIsNav(!isNav)
  }

  // routes
  let defaultRoutes = RoutesDefault;
  let albumRoutes = RoutesAlbums;
  let allRoutes = [...defaultRoutes, ...albumRoutes];
  let inAuthenticated = useRoutes(allRoutes);

  return (
    <>
      <LayoutHeader className={'header'}>
        <Link
          to="/"
          className="brand"
          onClick={isNav == true ? () => toggleNav() : ''}
        >
          <svg className="brand__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            enableBackground="new 0 0 512 512">
            <path
              d="m46 301.44v-91.57c0-13.456 5.056-24.335 15.171-32.646 9.483-7.857 21.04-11.784 34.679-11.784 14.539 0 26.774 6.277 36.711 18.828 8.756-12.551 22.17-18.828 40.23-18.828 12.549 0 23.388 4.247 32.513 12.733 9.392 8.76 14.09 19.324 14.09 31.697v91.57h-24.384v-84.12c0-7.676-1.539-13.906-4.617-18.696-4.17-6.588-10.595-9.888-19.287-9.888-17.388 0-26.08 9.531-26.08 28.584v84.12h-24.924v-82.5c0-8.669-1.901-15.667-5.705-20.998-4.346-6.139-10.687-9.21-19.02-9.21-16.66 0-24.992 10.658-24.992 31.971v80.73h-24.383m246.61.001v-91.57c0-13.456 5.06-24.335 15.174-32.646 9.477-7.857 21.04-11.784 34.675-11.784 14.544 0 26.775 6.277 36.711 18.828 8.761-12.551 22.17-18.828 40.23-18.828 12.553 0 23.392 4.247 32.513 12.733 9.391 8.76 14.09 19.324 14.09 31.697v91.57h-24.385v-84.12c0-7.676-1.543-13.906-4.621-18.696-4.157-6.588-10.594-9.888-19.289-9.888-17.385 0-26.08 9.531-26.08 28.584v84.12h-24.923v-82.5c0-8.669-1.907-15.667-5.705-20.998-4.351-6.139-10.687-9.21-19.02-9.21-16.663 0-24.995 10.658-24.995 31.971v80.73h-24.383m-26.978-173.39h-10.01-13.11v210.59c-5.05-1.668-10.429-2.516-16.16-2.516-5.24 0-10.609.632-16.12 1.898-5.514 1.263-11.607 3.342-18.288 6.23-6.323 4.153-12.1 8.355-17.337 12.599-5.244 4.241-9.664 8.356-13.277 12.324-7.765 10.116-11.649 20.499-11.649 31.15 0 9.936 3.885 17.882 11.649 23.844 8.129 5.963 17.34 8.94 27.635 8.94 11.556 0 23.437-2.751 35.626-8.26 12.193-5.511 22.17-13.321 29.938-23.438 7.403-10.654 11.11-20.95 11.11-30.886v-242.48h-.014" />
          </svg>
        </Link>
        <Button
          buttonType='button'
          className={'toggle' + `${!isNav ? " toggle__menu--open" : " toggle__menu--close"}`}
          name={isNav ? "navigation close" : "navigation open"}
          aria={isNav ? "navigation close" : "navigation open"}
          onClick={() => toggleNav()}
        />
      </LayoutHeader>
      <Nav
        className={isNav ? 'nav nav--active' : 'nav'}
        isNav={isNav}
        toggleNav={toggleNav}
        description={description}
        externalLinks={externalLinks}
      />
      <LayoutMain className={'main'} >
        {inAuthenticated}
      </LayoutMain>
    </>
  )
}