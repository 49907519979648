import React, { Children } from "react";

const Button = (props) => {

  const {
    buttonType, className, disabled, onClick, title, icon, destination, target,
    name, type, value, aria, children, tabIndex
  } = props;

  return (
    <>
      {buttonType === 'anchor'
        ? <a
          className={disabled ? className + '--disabled' : className}
          onClick={onClick}
          aria-label={aria ? aria : title}
          href={destination}
          target={target}
          rel="norefferer"
          tabIndex={tabIndex ? tabIndex : "0"}
        >
          {icon
            ? <i />
            : ''
          }
          {title}
          {children}
        </a>
        : ''
      }

      {buttonType === 'button'
        ? <button
          className={disabled ? className + '--disabled' : className}
          onClick={onClick}
          name={name}
          aria-label={aria ? aria : name}
          buttontype={type}
          value={value}
          tabIndex="0"
        >
          {icon
            ? <i />
            : ''
          }
          {title}
          {children}
        </button>
        : ''
      }

      {buttonType === 'div'
        ? <div
          className={disabled ? className + '--disabled' : className}
          onClick={onClick}
          tabIndex="0"
        >
          {icon
            ? <i />
            : ''
          }
          {title}
          {children}
        </div>
        : ''
      }
    </>
  )
}

export default Button;