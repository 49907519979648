import React from 'react';

class Loader extends React.Component {
  constructor() {
    super();
    this.state = {
      classNames: '--start',
    }
  }

  onAnimationEnd = () => {
    const { classNames } = this.state;

    this.setState({
      classNames: ''
    })
  };

  render() {
    const { animationFinished, classNames } = this.state;

    return <div
      className={'loading loading' + classNames}
      onAnimationEnd={this.onAnimationEnd}
    />
  }
}

export default Loader;