import React from 'react';
import PropTypes from "prop-types";
import Button from './TagButton';

const Modal = (props) => {

  const Controls =
    <div className="controls">
      <Button
        buttonType='button'
        className="controls--close"
        title='Close'
        name='Close'
        icon={true}
        onClick={props.modalClose}
      />
    </div>
    ;

  return (
    <div className="modal">
      {Controls}
      <div className="modal__heading">
        {props.caption}
        </div>
      <div className="modal__container">
        <img src={props.image} alt={props.alt ? props.alt : props.image} />
        {props.children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  onClick: PropTypes.func,
  caption: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.string
};

export default Modal;