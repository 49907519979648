import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SnapSlider from '@tannerhodges/snap-slider';
import Button from "./TagButton";

// slider refference
// https://tannerhodges.github.io/snap-slider/ && https://github.com/tannerhodges/snap-slider

const HeroSlider = ({ data, imagePath, slides, nav, duration }) => {
  const slideData = data;

  useEffect(() => {
    // Create a new slider.
    const slider = new SnapSlider('.hero', {
      id: 'slider-hero',
      nav: '.hero__nav',
      prev: 1,
      next: 1,
      start: 1,
      loop: true
    });

    slider.on('load', () => { sliderTimer });
    const sliderTimer = setInterval(() => { slider.goto('next') }, duration);

    return () => {
      clearInterval(sliderTimer);
    };
  }, []);

  const sliderSlides = slideData.map((element, index) =>
    <li className="slide" key={index}>
      <img src={imagePath + element.image} alt={element.image} title={element.image} />
      <div className="slide__details">
        <span className="title">{element.title}</span>
        <hr />
        <span className="description">{element.description}</span>
        <NavLink to={'/' + element.destination} className="btn link">{element.buttonTitle}</NavLink>
      </div>
    </li >
  );

  const sliderNav = (
    <ul className="hero__nav" data-snap-slider-nav="slider-hero">
      <li><Button className={'previous'} buttonType={'button'} data-snap-slider-goto="prev" name={'Previous'} icon={1} /></li>
      {/* {slideData.map(
        (element, index) => <li key={index}><button type="button" data-snap-slider-goto={index + 1}>{index + 1}</button></li>
      )} */}
      <li><Button className={'next'} buttonType={'button'} data-snap-slider-goto="next" name={'Next'} icon={1} /></li>
    </ul>
  );

  return (
    <>
      <ul className="hero" data-snap-slider="slider-hero">
        {slides ? sliderSlides : ''}
      </ul>
      {nav ? sliderNav : ''}
    </>
  )
}

export default HeroSlider;