import React from "react";

const TagFigure = ({ className, src, alt, caption, onClick, onKeyPress, tabIndex }) => {

  return (
    <figure
      className={className}
      onClick={onClick}
      onKeyPress={onKeyPress}
      tabIndex={tabIndex}
      >
      <img
        width=""
        height=""
        src={src}
        data-src={src}
        alt={alt}
        title={alt}
        loading="eager"
      />
      {caption ?<figcaption>{caption}</figcaption> : ''}
    </figure>
  )
}

export default TagFigure;