import React from "react";
import { NavLink } from "react-router-dom";
import Button from "./TagButton";
import { RoutesNavPublic } from '../routes/routes-public'

export const Nav = ({ className, children, isNav, toggleNav, externalLinks, description }) => {

  // nav routes
  let pubRoutes = RoutesNavPublic;


  const PublicLinks = pubRoutes.map((item) =>
    <li key={item.destination}>
      <NavLink
        to={'/' + item.destination}
        className={({ isActive }) => isActive ? 'btn--active' : ''}
        onClick={isNav ? () => toggleNav() : ''}
        tabIndex={isNav ? "0" : "-1"}
      >
        {item.name}
      </NavLink>
    </li>
  );

  const propertyLinks = externalLinks.map((item) =>
    <li key={item.id}>
      <Button
        buttonType="anchor"
        destination={item.destination}
        title={item.name}
        target="_blank"
        tabIndex={isNav ? "0" : "-1"}
      />
    </li>
  );

  const Copyright = (
    <span className="copyright">©2022 Michael Dillon Music. All rights reserved.</span>
  );

  return (
    <nav className={className}>
      <ul>
        {PublicLinks}
      </ul>
      <hr />
      <p>{description}</p>
      <ul>
        {propertyLinks}
      </ul>
      {Copyright}
      {children}
    </nav>
  )
}