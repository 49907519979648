import React from "react";

const AlbumHeader = ({ className, children, data }) => {

  return (
    <header className={className}>
      <span className={className + '--title'}>{data.album}</span>
      <hr className="divider" />
      <p className={className + '--description'}>{data.description}</p>
      <span className={className + '--artist'}>{data.artist}</span>
      <span className={className + '--year'}>Released: {data.year} ({data.tracks.length} Tracks)</span>
      {children}
    </header>
  )
}

export default AlbumHeader;