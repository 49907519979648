import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "./TagButton";

const ContactForm = (props) => {
  // const [submit, setSubmit] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [inputs, setInputs] = useState({});

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setInputs(values => ({...values, [id]: value}))
  }

  // when submit btn is clicked
  const onSubmit = (e) => {
    e.preventDefault();

    // check field validation here
    if (true === false) {
      return false;
    }

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the response state and the step
      setEmailStatus(xhr.responseText);
    });

    // open the request with the verb and the url
    xhr.open(
      "GET",
      process.env.REACT_APP_MAILER +
      "?sendto=" +
      inputs.email +
      "&name=" +
      inputs.name +
      "&phone=" +
      inputs.phone +
      "&message=" +
      inputs.message
    );

    // send the request
    xhr.send();

    // reset the fields
    setInputs("");
  };

  if (emailStatus !== false) {
    return (
      <article className="contact-response">
        {/* <p>{emailStatus}</p> */}
        <p>I'll be in touch soon, in the meantime, let's get back to discovering the site.</p>
        <Link to={"/"} className="btn__text">
          Return Home
        </Link>
      </article>
    );
  }

  return (
    <form className="contact-form" id="contact-form" onSubmit={onSubmit} method="POST">
      <header>
        <h2 className="form--title">Let's Talk</h2>
        <hr />
        <p>Feel free to send me a message by filling out the form below.</p>
      </header>
      <div className="element">
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          value={inputs.name || ""}
          onChange={handleChange}
          placeholder="Name"
          required
        />
      </div>
      <div className="element">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={inputs.email || ""}
          onChange={handleChange}
          placeholder="Email"
          required
        />
      </div>
      <div className="element">
        <label htmlFor="phone">Phone</label>
        <input
          id="phone"
          type="tel"
          pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
          value={inputs.phone || ""}
          onChange={handleChange}
          placeholder="0000000000"
          required
        />
      </div>
      <div className="element">
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          rows="1"
          value={inputs.message || ""}
          onChange={handleChange}
          placeholder="Message"
          required
        />
      </div>
      <Button
        buttonType="button"
        className="btn"
        type="submit"
        title="Send Message"
      />
    </form>
  );
};

ContactForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  message: PropTypes.string,
};

export default ContactForm;