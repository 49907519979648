import React, { useState, useEffect } from "react";
import { fetchJson } from '../utilities/utils';

// templates
import { LayoutContainer } from '../layout/Layout';

//components
import Loader from "../components/Loader";
import HeroSlider from '../components/HeroSlider';

const Home = ({ className }) => {
  // data & pathing
  const { REACT_APP_DATA, REACT_APP_IMAGES } = process.env;
  const endpoint = `${REACT_APP_DATA}/pageData`;
  const imagePath = REACT_APP_IMAGES + '/album_covers/';
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    fetchJson(endpoint).then((ActualData) => {
      setData(ActualData.data);
      setError(null);
      // console.log('data', ActualData.data)
    })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  let sliderData = data[0];
  // console.log('data', sliderData.sliderContent)

  if (loading) return <Loader />;

  if (error || !data) {
    return <p>There was an error loading your data!</p>;
  }

  return (
    <LayoutContainer className={className} >
      <HeroSlider
        data={sliderData.sliderContent}
        imagePath={imagePath}
        slides={1}
        nav={1}
        duration={10000}
      />
    </LayoutContainer>
  )
}

export default Home;