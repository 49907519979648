import React, { useEffect } from "react";

// templates
import { LayoutContainer } from '../layout/Layout';

// components
import ContactForm from '../components/ContactForm'

const Contact = ({ className }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);
  
  return (
    <LayoutContainer className={className} >
      <ContactForm />
    </LayoutContainer>
  )
}

export default Contact;