import React from "react";

export const LayoutHeader = ({ className, children }) => {

  return (
    <header className={className}>
      {children}
    </header>
  )
}

export const LayoutMain = ({ className, children }) => {

  return (
    <main className={className}>
      {children}
    </main>
  )
}
export const LayoutContainer = ({ className, children }) => {

  return (
    <section className={className}>
      {children}
    </section>
  )
}