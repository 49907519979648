import React from "react";

const AlbumTrack = ({ children, data }) => {

  return (
    <>
    <span className="album__track--id">{data.trackId + '.'}</span>
    <span className="album__track--title">{data.trackTitle}</span>
    <span className="album__track--artist">Performed by: {data.trackArtist}</span>
      {children}
    </>
  )
}

export default AlbumTrack;