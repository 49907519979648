import React from "react";

const TagPicture = ({ className, src, alt }) => {

  return (
    <picture className={className}>
      <source
        media=""
        srcSet={src}
      />
      <img
        width=""
        height=""
        src={src}
        data-src={src}
        alt={alt}
        title={alt}
        loading="eager"
      />
    </picture>
  )
}

export default TagPicture;