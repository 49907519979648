import React, { useState, useEffect } from "react";
import { fetchJson } from '../utilities/utils';

// templates
import { LayoutContainer } from '../layout/Layout';

import Loader from "../components/Loader";
import AlbumHeader from '../components/AlbumHeader';
import AlbumTrack from '../components/AlbumTrack';
import TagPicture from '../components/TagPicture';
import Lyrics from '../components/TagDetail';
import Button from "../components/TagButton";

const Album = ({ className, id, children }) => {
  // data & pathing
  const { REACT_APP_DATA, REACT_APP_IMAGES } = process.env;
  const endpoint = `${REACT_APP_DATA}/albums`;
  const imagePath = `${REACT_APP_IMAGES}/album_covers/`;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // other
  const details = document.querySelectorAll("details");

  useEffect(() => {
    window.scrollTo(0, 0);

    lyricsToggle()

    return () => {
      lyricsClose()
    }
  }), [];

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchJson(endpoint).then((ActualData) => {
      setData(ActualData.data);
      setError(null);
      // console.log('data', ActualData.data)
    })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [id]);

  const lyricsClose = () => {
    details.forEach((detail) => {
      if (detail.open) {
        detail.removeAttribute("open");
      }
    });
  }

  const lyricsToggle = () => {

    // Add the onclick listeners.
    details.forEach((target) => {

      target.addEventListener("click", () => {

        target.scrollIntoView({ behavior: "smooth" })
        // Close all the details that are not target.
        details.forEach((detail) => {
          if (detail !== target) {
            detail.removeAttribute("open");
          }
        });
      });
    });
  }

  let dataAlbum = data[id];

  if (loading) return <Loader />;

  if (error || !data) {
    return <p>There was an error loading your data!</p>;
  }

  return (
    <LayoutContainer className={className}>
      <Loader />
      <TagPicture className={`${className}__art`} src={imagePath + dataAlbum.art} />
      <AlbumHeader className={`${className}__header`} data={dataAlbum}>
        <div className="album__header--links">
          {dataAlbum.purchaseLinks[0].itunes ?
            <Button
              className={'btn buy'}
              buttonType="anchor"
              destination={dataAlbum.purchaseLinks[0].itunes}
              target="_blank"
              icon={1}
              title={'Buy from iTunes'}
            />
            : ''
          }
          {dataAlbum.purchaseLinks[0].appleMusic ?
            <Button
              className={'btn buy'}
              buttonType="anchor"
              destination={dataAlbum.purchaseLinks[0].appleMusic}
              target="_blank"
              icon={1}
              title={'Listen on Apple Music'}
            />
            : ''
          }
          {dataAlbum.purchaseLinks[0].spotify ?
            <Button
              className={'btn buy'}
              buttonType="anchor"
              destination={dataAlbum.purchaseLinks[0].spotify}
              target="_blank"
              icon={1}
              title={'Listen on Spotify'}
            />
            : ''
          }
        </div>
      </AlbumHeader>
      <ul className={`${className}__tracks`}>
        {
          dataAlbum.tracks.map((track, index) =>
            <li className={`${className}__track`} key={track.trackId} >
              <AlbumTrack data={track} />
              {track.trackLyrics.length != 0 ?
                <Lyrics summary={'Lyrics'} details={<pre className={`${className}__lyrics`}>{track.trackLyrics}</pre>} />
                : ''
              }
            </li>
          )
        }
      </ul>
    </LayoutContainer>
  )
}

export default Album;