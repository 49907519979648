import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import { fetchData } from '../utilities/utils';

// templates
import { LayoutContainer } from '../layout/Layout';
import Loader from "../components/Loader";
import TagFigure from "../components/TagFigure";
import Modal from "../components/Modal";

const About = ({ className }) => {
  const { REACT_APP_PAGES, REACT_APP_IMAGES } = process.env;
  const endpoint = `${REACT_APP_PAGES}/about.md`;
  const imagePath = `${REACT_APP_IMAGES}/mdm/`;
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [modal, setmodal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalDesc, setModalDesc] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    fetchData(endpoint).then((ActualData) => {
      setData(ActualData.data);
      setError(null);
      // console.log('data', ActualData.data)
    })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const modalOpen = (e) => {
    e.preventDefault();
    setModalImage(e.target.src);
    setModalDesc(e.target.alt)
    setmodal(!modal);
    // body.classList.add("modal");
  }

  const modalOpenKeyPress = (e) => {
    e.preventDefault();
    setModalImage(e.target.firstElementChild.src);
    setModalDesc(e.target.firstElementChild.alt)
    setmodal(!modal);
    // body.classList.add("modal");
  }

  const modalClose = (e) => {
    e.preventDefault();
    setModalImage('');
    setmodal(!modal);
    // body.classList.remove("modal");
  }

  const MdImage = props => {
    return (
      <TagFigure
        key={props.src}
        className={"about__image"}
        src={imagePath + props.src}
        alt={props.alt}
        caption={props.alt}
        onClick={(e) => modalOpen(e)}
        onKeyPress={(e) => modalOpenKeyPress(e)}
        tabIndex={0}
      />
    );
  };

  if (loading) return <Loader />;

  if (error || !data) {
    return <p>There was an error loading your data!</p>;
  }

  return (
    <LayoutContainer className={className} >
      <Markdown
        children={data}
        options={{
          wrapper: React.Fragment,
          overrides: {
            img: {
              component: MdImage
            },
          },
        }}
      />
      {modal ?
        <Modal
          modalClose={(e) => modalClose(e)}
          image={modalImage}
          caption={modalDesc}
        />
        : ''
      }
    </LayoutContainer>
  )
}

export default About;