import React, { props } from "react";

// public pages
import Home from '../views/Home';
import About from '../views/About';
import Apocalypse from '../views/Apocalypse';
import Aao from '../views/Aao';
import Trophos from "../views/Trophos";
import Boin from '../views/Boin';
import Album from '../views/Album';
import Contact from '../views/Contact';
import Oops from '../views/Oops';

export const RoutesDefault = [
  {
    path: "/contact",
    element: <Contact className={'contact'} />
  },
  {
    path: "/what_about_me",
    element: <About className={'about'} />
  },
  {
    path: "/apha_and_omega",
    element: <Aao className={'aao'} />
  },
  {
    path: "/trophos_vs_loshusquare",
    element: <Trophos className={'trophos'} />
  },
  {
    path: "/apocalypse_series",
    element: <Apocalypse className={'apocalypse'} />
  },
  {
    path: "/boin",
    element: <Boin className={'boin'} />
  },
  {
    path: "*",
    element: <Oops className={'oops'} />
  },
  {
    path: "/",
    element: <Home className={'home'} />
  }
];

export let RoutesAlbums = [
  {
    path: "/sb",
    element: <Album className={'album'} id={0} />
  },
  {
    path: "/faw",
    element: <Album className={'album'} id={1}/>
  },
  {
    path: "/dita",
    element: <Album className={'album'} id={2}/>
  },
  {
    path: "/oscillation",
    element: <Album className={'album'} id={3}/>
  },
  {
    path: "/sod",
    element: <Album className={'album'} id={4}/>
  },
  {
    path: "/sqn1",
    element: <Album className={'album'} id={5}/>
  },
  {
    path: "/dita",
    element: <Album />
  },
]

// navigation
export let RoutesNavPublic = [
  { name: "Home", destination: "" },
  { name: "What About Me?", destination: "what_about_me" },
  { name: "The Alpha and the Omega", destination: "apha_and_omega" },
  { name: "Trophos vs Lo Shu Square", destination: "trophos_vs_loshusquare" },
  { name: "Apocalypse Art Series", destination: "apocalypse_series" },
  { name: "Book of Infinite Numbers", destination: "boin" },
  { name: "Contact", destination: "contact" },
  { name: "Story Box ", destination: "sb" },
  { name: "Forces At Work", destination: "faw" },
  { name: "Death In the Air", destination: "dita" },
  { name: "God Matrix - Oscillation 666", destination: "oscillation" },
  { name: "God Matrix - Star of David", destination: "sod" },
  { name: "God Matrix - Spin Quantum Number 1", destination: "sqn1" }
];